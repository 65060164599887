@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

:root {
  --common-font: "DM Sans", sans-serif;
}

body,
* {
  margin: 0;
  font-family: var(--common-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
